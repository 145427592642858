import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', meta: {title: 'Главная',}, component: () => import('./views/Home.vue') },
  { path: '/demo/', meta: {title: 'demo',}, component: () => import('./views/Analytic/Demo.vue') },

  // { path: '/user/', meta: {title: 'Пользователи'}, component: () => import('./views/User/Users') },
  {
    path: '/desktop/',
    meta: {title: 'Рабочий стол',},
    component: () => import('./views/Desktop/Desktop.vue')
  },
  {
    path: '/user/:type/',
    meta: {
      title: 'Пользователи',
      tabs: [
        {title:"Клиенты", path:'/user/client/', icon:"mdi-account-outline", iconActive:"mdi-account"},
        {title:"Специалисты", path:'/user/spec/', icon:"mdi-doctor", iconActive:"mdi-doctor"},
        {title:"Администраторы", path:'/user/admin/', icon:"mdi-account-tie", iconActive:"mdi-account-tie"},
      ],
    },
    component: () => import('./views/User/Users')
  },

  { path: '/user/:type/:id/', meta: {title: 'Клиент'}, component: () => import('./views/User/User') },

  { path: '/visit/', meta: {title: 'Визиты за день',}, component: () => import('./views/Visit/Visits.vue') },
  { path: '/visit/list/', meta: {title: 'Визиты за период',}, component: () => import('./views/Visit/VisitsList.vue') },
  { path: '/userSpecPlan/', meta: {title: 'План работы специалистов',}, component: () => import('./views/UserSpec/UserSpecPlansDay.vue') },
  { path: '/userSpecPlans/', meta: {title: 'План работы специалистов',}, component: () => import('./views/UserSpec/UserSpecPlans.vue') },
  { path: '/userSpecPlanOld/', meta: {title: 'План работы специалистов (старая версия)',}, component: () => import('./views/UserSpec/UserSpecPlansMonth.vue') },
  { path: '/price/', meta: {title: 'Прайс-лист',}, component: () => import('./views/Price/Price.vue') },
  { path: '/service/', meta: {title: 'Услуги',}, component: () => import('./views/Service/Services.vue') },
  { path: '/financeOld/', meta: {title: 'Финансы',}, component: () => import('./views/Finance/FinanceOld.vue') },
  { path: '/finance/', meta: {title: 'Финансы (тестирование)',}, component: () => import('./views/Finance/Finance.vue') },
  { path: '/settings/', meta: {title: 'Настройки'}, component: () => import('./views/Settings/Settings') },
  { path: '/analytic/', meta: {title: 'Аналитика',}, component: () => import('./views/Analytic/Analytics.vue') },

  { path: '/insur/', meta: {title: 'Страховые компании'}, component: () => import('./views/Insur/Insurs.vue') },

  {path: '/call/', meta: {title: 'Телефония'}, component: () => import('./views/Call/Calls.vue')},
  //temporary
  {
    path: '/visitService/',
    meta: {
      title: 'Оказанные услуги',
    },
    component: () => import('./views/VisitService/VisitServices.vue')
  },
  {
    path: '/VisitServiceOld/',
    meta: {
      title: 'Оказанные услуги (OLD)',
    },
    component: () => import('./views/VisitService/VisitServicesOld.vue')
  },
 
  { path: '/medPlan/', meta: {title: 'Планы лечения',}, component: () => import('./views/MedPlan/MedPlans.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});
router.afterEach((to) => {
  document.title = (typeof to.meta=='object' ? to.meta : to.meta(to)).title
})

export default router
