<template>
  <v-sheet color="transparent">
    <v-progress-circular
        :color="color"
        :value="progress"
        :size="typeof size==='undefined' ? 48 : size"
        style="top:50%;left:50%"
        width="2"
        rotate="-90"
    />
    <v-btn icon @click="$emit('click')">
      <slot name="default"/>
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  props: ['color','size','time'],
  data: () => ({
    progress: 0,
    timer: null
  }),
  mounted() {
    this.progress = 0;
    this.timer = setInterval(()=>{
      this.progress += 100/(this.time/200);
    }, 200)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>
