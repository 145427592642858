import axios from "axios";

export default class Api {
  static host = '/api'
  static responseComplete = function(response, successCallback, errorCallback){
    try {
        successCallback(response.data);
    }catch (e){
        if (errorCallback!=null) errorCallback(e);
        Api.responseError(e);
    }
    if (typeof response.headers['x-debug-token-link']!='undefined')
      console.log(response.config.method+' '+response.config.url, 'profiler: '+response.headers['x-debug-token-link']);
  }
  static responseError = function(e, errorCallback){
      if (errorCallback!=null) errorCallback(e);
      console.error(e);
  }
  static poolRequest = function(...args){
    let results = [];
    args.forEach((func, index)=>{
      if (index<args.length-1){
        if (func==null){
          results[index] = null;
        } else {
          func((v1)=>{
            results[index] = {
              isSuccess:true,
              result:v1
            };
          }, (v1)=>{
            results[index] = {
              isSuccess:false,
              result:v1
            };
          });
        }
      }
    });

    let timer = setInterval(()=>{
      if (results.length===args.length-1){
        let isCorrect = true;
        for(let i=0;i<results.length;i++)
          isCorrect = typeof results[0]==='undefined' ? false : isCorrect;
        if (isCorrect){
          clearInterval(timer);
          args[args.length-1](results);
        }
      }
    }, 50, 50);

  }

  static service = {
    media:{
      mediaDirs(page, onPage, success, error=null){
        axios.get(Api.host+'/media/mediaDir/', {
          params: {
            page: page,
            onPage: onPage
          }
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplateCategory(dir, success, error=null){
        axios.get(Api.host+'/media/mediaTemplateCategory/', {
          params: dir!==0 ? {mediaDirId: dir} : null
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplates(mediaDirId, success, error=null){
        axios.get(Api.host+'/media/mediaTemplate/', {
          params: {
            mediaDirId: mediaDirId,
          }
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplateValues(success, error=null){
        axios.get(Api.host+'/media/mediaTemplate/value/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplateCreate(params, success, error=null){
        let formData = new FormData();
        Object.keys(params).forEach((key)=>{
          formData.append(key, params[key]);
        });

        axios.post(Api.host+'/media/mediaTemplate/', formData)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplateUpdate(id, params, success, error=null){
        let formData = new FormData();
        Object.keys(params).forEach((key)=>{
          formData.append(key, params[key]);
        });

        axios.post(Api.host+'/media/mediaTemplate/'+id+'/', formData)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaTemplateDelete(id, success, error=null){
        axios.delete(Api.host+'/media/mediaTemplate/'+id+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medias(mediaDirId, userId, page, onPage, success, error=null){
        axios.get(Api.host+'/media/media/', {
          params: {
            mediaDirId: mediaDirId,
            userId: userId,
            page: page,
            onPage: onPage
          }
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      media(id, success, error=null){
        axios.get(Api.host+'/media/media/'+id+'/', {
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaCreate(id, userId, success, error=null){
        axios.get(Api.host+'/media/mediaTemplate/'+id+'/mediaCreate/?userId='+userId)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaUpdate(id, params, success, error=null){
        axios.put(Api.host+'/media/media/'+id+'/', params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaDelete(id, success, error=null){
        axios.delete(Api.host+'/media/media/'+id+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaPreview(id, success, error=null){
        axios.get(Api.host+'/media/media/'+id+'/preview/', {
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      mediaEmailSend(id, success, error=null){
        axios.get(Api.host+'/media/media/'+id+'/emailSend/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    medRecord:{
      medRecordTemplateCategorys(page, onPage, showDeleted, success, error=null){
        axios.get(Api.host+'/medRecord/medRecordTemplateCategory/'+(showDeleted ? '?showDeleted' : ''), {
          params: {
            page: page,
            onPage: onPage
          }
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateCategoryCreate(params, success, error=null){
        axios.post(Api.host+'/medRecord/medRecordTemplateCategory/', params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateCategoryUpdate(params, success, error=null){
        axios.post(Api.host+'/medRecord/medRecordTemplateCategory/'+params.id+'/', params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateCategoryDelete(medRecordTemplateCategoryId, success, error=null){
        axios.delete(Api.host+'/medRecord/medRecordTemplateCategory/'+medRecordTemplateCategoryId+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateCreate(medRecordTemplateCategoryId, success, error=null){
        axios.post(Api.host+'/medRecord/medRecordTemplate/', {
          medRecordTemplateCategoryId: medRecordTemplateCategoryId
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplate(medRecordTemplateId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecordTemplate/'+medRecordTemplateId+"/")
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateCopy(medRecordTemplateId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecordTemplate/'+medRecordTemplateId+'/copy/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateUpdate(params, success, error=null){
        axios.post(Api.host+'/medRecord/medRecordTemplate/'+params.id+'/', params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateDelete(medRecordTemplateCategoryId, success, error=null){
        axios.delete(Api.host+'/medRecord/medRecordTemplate/'+medRecordTemplateCategoryId+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecords(userId, page, onPage, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/?userId='+userId, {
          params: {
            page: page,
            onPage: onPage
          }
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecord(id, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/'+id+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordCreate(medRecordTemplateId, userId, success, error=null){
        axios.post(Api.host+'/medRecord/medRecord/',{
          medRecordTemplateId:medRecordTemplateId,
          userId:userId
        })
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordUpdate(medRecordId, params, success, error=null){
        axios.post(Api.host+'/medRecord/medRecord/'+medRecordId+'/',params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordDownload(medRecordId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/'+medRecordId+'/download/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordEmailSend(medRecordId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/'+medRecordId+'/emailSend/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordCopy(medRecordId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/'+medRecordId+'/copy/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordPreview(medRecordId, success, error=null){
        axios.get(Api.host+'/medRecord/medRecord/'+medRecordId+'/preview/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordTemplateFastUpdate(medRecordTemplateId, params, success, error=null){
        axios.post(Api.host+'/medRecord/medRecordTemplate/'+medRecordTemplateId+'/fastUpdate/',params)
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medRecordDelete(id, success, error=null){
        axios.delete(Api.host+'/medRecord/medRecord/'+id+'/')
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    user:{
      list(filters, page, onPage, success, error=null){
        axios.get(Api.host+'/user/?filters='+JSON.stringify(filters)+'&page='+page+'&onPage='+onPage, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      login(phone, pass, success, error=null){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("pass", pass);
        axios.post(Api.host+'/user/login/', formData, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      status(success, error=null){
        axios.get(Api.host+'/user/status/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      logout(success, error=null){
        axios.get(Api.host+'/user/logout/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      create(data, success, error=null){
        axios.post(Api.host+'/user/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      family(id, success, error=null){
        axios.get(Api.host+'/user/'+id+'/family/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      familySave(id, parents, mariedId, childs, success, error=null){
        let formData = new FormData();
        parents.forEach((v)=>{
          formData.append("parents[]", v);
        });
        if (mariedId!==null)
          formData.append("mariedId", mariedId);
        childs.forEach((v)=>{
          formData.append("childs[]", v);
        });
        axios.post(Api.host+'/user/'+id+'/family/', formData, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      familyTree(id, success, error=null){
        axios.get(Api.host+'/user/'+id+'/family/tree/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      merge(data, success, error=null){
        axios.post(Api.host+'/correct/merge/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      delete(id, success, error=null){
        axios.get(Api.host+'/correct/delete/'+id, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    visit:{
      visit(id, success, error=null){
        axios.get(Api.host+'/visit/'+id+'/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitByClient(userId, success, error=null){
        axios.get(Api.host+'/visit/byClient/'+userId+'/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      promoSources(success, error=null){
        axios.get(Api.host+'/user/promoSource/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error, true))
            .catch(e => Api.responseError(e, error));
      },
      serviceAdd(visitId, servicesId, success, error=null){
        axios.post(Api.host+'/visit/'+visitId+'/visitService/', servicesId, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitServiceAdd(visitId, visitServicesId, success, error=null){
        axios.post(Api.host+'/visit/'+visitId+'/visitServiceAdd/', visitServicesId, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitServiceDelete(visitId, visitServiceId, success, error=null){
        axios.delete(Api.host+'/visit/'+visitId+'/visitService/'+visitServiceId+'/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitPaySummary(visitId, params, success, error=null){
        axios.post(Api.host+'/visit/'+visitId+'/paySummary/', params, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitPays(visitId, success, error=null){
        axios.get(Api.host+'/visit/'+visitId+'/pay/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      visitPayCreate(visitId, cashBoxId, source, amount, comment, success, error=null){
        axios.post(Api.host+'/visit/'+visitId+'/pay/', {
          cashBoxId:cashBoxId,
          source:source,
          amount:amount,
          comment:comment
        }, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      changeUser(data, success, error=null){
        axios.post(Api.host+'/correct/replace/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    pay:{
      payCreate(userId, cashBoxId, source, amount, comment, success, error=null){
        axios.post(Api.host+'/pay/pay/', {
          userId:userId,
          cashBoxId:cashBoxId,
          source:source,
          type:'accountIn',
          amount:amount,
          comment:comment,
        }, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      payOutCreate(userId, cashBoxId, amount, comment, success, error=null){
        axios.post(Api.host+'/pay/pay/', {
          userId:userId,
          cashBoxId:cashBoxId,
          source:'cash',
          type:'accountOut',
          amount:amount,
          comment:comment,
        }, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      types(success, error=null){
        axios.get(Api.host+'/pay/pay/type/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      sources(success, error=null){
        axios.get(Api.host+'/pay/pay/source/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      pays(userId, success, error=null){
        axios.get(Api.host+'/pay/pay/?userId='+userId, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      pay(id, success, error=null){
        axios.get(Api.host+'/pay/pay/'+id+'/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      paySummary(userId, dateFrom, dateTo, success, error=null){
        let q = '';
        q += userId==null ? '' : (q.indexOf('?')===-1 ? '?' : '&')+'userId='+userId;
        q += dateFrom==null ? '' : (q.indexOf('?')===-1 ? '?' : '&')+'dateFrom='+dateFrom;
        q += dateTo==null ? '' : (q.indexOf('?')===-1 ? '?' : '&')+'dateTo='+dateTo;
        axios.get(Api.host+'/pay/pay/summary/'+q, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      payRemainUsers(success, error=null){
        axios.get(Api.host+'/pay/pay/summary/remain/user/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      payAccept(id, tranId, success, error=null){
        axios.post(Api.host+'/pay/pay/'+id+'/accept/', {tranId:tranId}, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      payUpdate(id, data, success, error=null){
        axios.post(Api.host+'/pay/pay/'+id+'/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      }
    },
    medPlan:{
      medPlan(id, success, error=null){
        axios.get(Api.host+'/medPlan/medPlan/'+id+'/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medPlanCreate(data, success, error=null){
        axios.post(Api.host+'/medPlan/medPlan/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medPlanDelete(id, success, error=null){
        axios.delete(Api.host+'/medPlan/medPlan/'+id+'/',{withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medPlanVisitServiceDelete(medPlanId, ids, success, error=null){
        axios.post(Api.host+'/medPlan/medPlan/'+medPlanId+'/visitServiceDelete/', ids, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      medPlanVisitServiceCreate(medPlanId, data, success, error=null){
        axios.post(Api.host+'/medPlan/medPlan/'+medPlanId+'/visitServiceCreate/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    service:{
      copy(data, success, error=null){
        axios.post(Api.host+'/serviceCatService/copy/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      move(data, success, error=null){
        axios.post(Api.host+'/serviceCatService/move/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      delete(data, success, error=null){
        axios.post(Api.host+'/serviceCatService/delete/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      servicesPriceLock(ids, success, error=null){
        axios.put(Api.host+'/service/', ids, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      serviceHelpersForUpdate(id, name, code, price, success, error=null){
        axios.get(Api.host+'/service/'+id+'/helper/?name='+name+'&code='+code+'&price='+price,{withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      serviceHelpersForCreate(serviceCatId, name, code, price, success, error=null){
        name = name==null ? '' : name;
        code = code==null ? '' : code;
        price = price==null ? 0 : price;
        axios.get(Api.host+'/service/helper/?serviceCatId='+serviceCatId+'&name='+name+'&code='+code+'&price='+price,{withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      search(
          searchText,
          isSearchInServiceCat,
          serviceCatId,
          isSearchInServiceCatProgram,
          isSearchService,
          isSearchServiceCat,
          isSearchServiceCatProgramOnly,
          insurOmsId,
          insurDmsId,
          insurContractId,
          userspecId,
          userspecOnly,
          servicesId = [],
          success, error=null){
        let url = Api.host+'/serviceCat/search/?';
        if (isSearchInServiceCat) url += 'serviceCatId='+serviceCatId+'&';
        if (isSearchInServiceCatProgram) url += 'isServiceCatProgram=1&';
        if (isSearchService) url += 'isService=1&';
        if (isSearchServiceCat) url += 'isServiceCat=1&';
        if (isSearchServiceCatProgramOnly) url += 'isServiceCatProgramOnly=1&';
        if (insurOmsId) url += 'insurOmsId='+insurOmsId+'&';
        if (insurDmsId) url += 'insurDmsId='+insurDmsId+'&';
        if (insurContractId) url += 'insurContractId='+insurContractId+'&';
        if (userspecId) url += 'userspecId='+userspecId+'&';
        if (userspecOnly) url += 'userspecOnly=1&';
        if (servicesId.length>0)
          servicesId.forEach(v=>{
            url += 'servicesId[]='+v+'&';
          });
        if(searchText!==null && searchText!=='')
          url += 'q='+searchText;

        axios.get(url,{withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      restoreDefaultPrice(serviceCat, service, success, error=null){
        //{"serviceCat":[12645],"service":[]}
        axios.post(Api.host+'/serviceCatProgram/original/', {serviceCat: serviceCat,service: service}, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      validation(data, success, error=null){
        axios.post(Api.host+'/service/validate/', data, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    emulate(success, error){
      setTimeout(() => {
        if (Math.random() < 0.5){
          success();
        } else {
          error();
        }
      }, 500)
    },
  }

  static graphql = function(query, vars, success, error) {
    axios.post(Api.host+'/graphql/', {
      query: query.replace('  ',''),
      variables: vars
    })
        .then(r =>{
          console.log(query.trim(), vars, r.data.data, r.headers['x-debug-token-link']);
          if(typeof r.data.data==='undefined'){
            console.error(r.data.errors);
            if(typeof error!=='undefined')
              error(r.data.errors)
          } else {
            success(r.data.data, JSON.parse(r.headers['total']), JSON.parse(r.headers['sum']))
          }
        })
        .catch(e =>{
          console.error(e);
          if(typeof error!=='undefined')
            error(e);
        });
  }

  static storage = {
    createUpdate(entity, data, success=null, error=null) {
      if(typeof data.id==='undefined')
        axios.post(Api.host+'/storage/'+entity+'/', data)
          .then(r =>{
            if(success!==null) success(r);
          }).catch(e =>{
            if(error!==null) error(e);
          });
      else
        axios.put(Api.host+'/storage/'+entity+'/'+data.id+'/', data)
            .then(r =>{
              if(success!==null) success(r);
            }).catch(e =>{
          if(error!==null) error(e);
        });
    },
    delete(entity, id, success=null, error=null) {
      axios.delete(Api.host+'/storage/'+entity+'/'+id+'/')
        .then(r =>{
          if(success!==null) success(r);
        }).catch(e =>{
          if(error!==null) error(e);
        });
    },
  }

  static model = {
    Pay:{
      types: {
        pay:{"name":"\u041e\u043f\u043b\u0430\u0442\u0430","color":"green","icon":"mdi-arrow-up"},
        payCancel:{"name":"\u041e\u0442\u043c\u0435\u043d\u0430 \u043e\u043f\u043b\u0430\u0442\u044b","color":"red","icon":"mdi-arrow-u-down-right"},
        accountIn:{"name":"\u041f\u043e\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u0435","color":"green","icon":"mdi-arrow-up-circle"},
        accountOut:{"name":"\u0421\u043d\u044f\u0442\u0438\u0435","color":"red","icon":"mdi-arrow-down-circle"},
        internalIn:{"name":"\u041f\u0440\u0438\u0445\u043e\u0434","color":"green","icon":"mdi-arrow-right-circle"},
        internalOut:{"name":"\u0420\u0430\u0441\u0445\u043e\u0434","color":"red","icon":"mdi-arrow-left-circle"}
      },
      sources: {
        cash: {"name": "\u041d\u0430\u043b\u0438\u0447\u043d\u044b\u0435", "icon": "mdi-cash"},
        card: {"name": "\u0411\u0430\u043d\u043a\u043e\u0432\u0441\u043a\u0430\u044f \u043a\u0430\u0440\u0442\u0430", "icon": "mdi-credit-card-outline"},
        bankAccount: {"name": "\u0420\u0430\u0441\u0447\u0435\u0442\u043d\u044b\u0439 \u0441\u0447\u0435\u0442", "icon": "mdi-bank"},
        account: {"name": "\u041b\u0438\u0447\u043d\u044b\u0439 \u0441\u0447\u0435\u0442", "icon": "mdi-account-cash-outline"},
        oms: {"name": "ОМС", "icon": "mdi-plus-box"},
        dms: {"name": "ДМС", "icon": "mdi-plus-box-multiple"},
        contract: {"name": "Контракт", "icon": "mdi-domain"},
        insur: {"name": "Организация", "icon": "mdi-briefcase-outline"}
      }
    },
    Visit:{
      statuses:[
        {"id":"pre","name":"Предварительно","color":"cyan"},//#029FC0
        {"id":"confirmed","name":"Подтверждено","color":"orange"},//#FF9800
        {"id":"ready","name":"Пришел","color":"purple"},//#553E79
        {"id":"active","name":"На приеме","color":"blue"},//#C01C73
        {"id":"complete","name":"Завершен","color":"green"},//#4CAF50
        {"id":"notReady","name":"Не пришел","color":"red"},//#553E79
        {"id":"cancel","name":"Отказался","color":"red"},//#9B9B9B
        {"id":"rejected","name":"Отказано","color":"red"},//#F44336
        {"id":"move","name":"Перенести","color":"grey"},//#795548
        {"id":"self","name":"Самозапись","color":"#000000"},
      ]
    }
  };
}



